@import "./global.scss";

.contact {
    background-color: $primary;
    border-bottom: 2px solid rgb(25, 25, 124);
    .contact-wrapper {
        padding: 2rem 0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        .contact-icon {
            font-size: 2.5rem;
            margin-top: -1.2rem;
            padding-right: .8rem;
        }

        .contact-info {
            h2 {
                font-size: 1.5rem;
            }
            p {
                font-size: .8rem;
            }
        }
    }
}