
/** Variables **/

// My theme Color 

$purple-dark : #0e0bb3fe;
$purple-light : #f8ea6b;
$pink : #d1a3e7 ;
$footer : #151414;


$primary : $purple-dark ;
$secondary : $pink;

//utility

$smooth: 0.4s all ease-in-out;



// Gray-White Color
$white:    #fff;
$gray-300: #dee2e6;
$gray-600: #6c757d;



// Gray-White Color
$white:    #fff;
$black : #000;


//  Gradient Mixin 

@mixin gradient{
  background: linear-gradient(to right, rgba($primary,0.95), rgba($secondary, 0.95));   
}

// Absolute Center position
@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// Button-style

.btn-rounded{
    position: relative;
    padding: 0.8375rem 4.375rem 0.8375rem 1.25rem;
    font-weight: 700;
    margin: 0;
    border: 0.1rem solid rgba($white, 0.6);
    background-color: $white;
    display: block;
    transition: $smooth;
  
    
    span {
      position: absolute;
      background-color: $primary;
      width: 2.5rem;
      height: 2.5rem;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      border-radius: 50%;
  
      i {
          color: $white;
          font-size: 1rem;
          line-height: 2.5rem; // place call icon at center
      }
  }
  
  
    &:hover {
        @include gradient;  // call mixin function
        color: $white;
  
        span {
            background-color: rgba($black, 0.6);
        }
    }
  
  }

  // Tippography 

    // Heading line design

  
.heading-line::before{
  content:" ";
  height:0.1rem;
  width:10rem;
  margin:0 auto;
  display:block;
  background : $primary ;
}


.heading-line::after{
  content:" ";
  height:0.8rem;
  width:2.5rem;
  margin:0 auto;
  display:block;
  background : $secondary ;
}

// Social Icon styling

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  * {
    margin-right: 1rem;
  }
  
}


  