
@import "./global.scss";

.intro-section {
  @include gradient;
  padding: 6rem 0 0 0;
  width: 100%;
  height: 100%;
  @media screen and(max-width:576px) {
    padding-top: 4rem;
}


  .intros {
      margin-top: 1rem;
      padding: 1rem 2rem;


      .title {
          font-size: 3.2rem;
          font-weight: 600;
          display: inline-block;
          font-family: "Poppins";
          text-transform: capitalize;

          @media screen and(max-width:576px) {
              font-size: 2rem;

          }

      }
      .description {
          font-size: 2rem;
          display: block;
          text-transform: none;
          text-align: justify;
          margin: 2rem 0;

          @media screen and(max-width:576px) {
            font-size: 1.6rem;
        }
      }

      img {
          width: 100%;
          height: 60%;
          border-radius: 40px;
          box-shadow: 10px 15px 10px rgba( 0, 0, 0, 0.2);
      }
  }


} // intro-section





