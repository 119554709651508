@import "./global.scss";

.aboutus {
    p{
        font-size: 1.25rem;
    }

    .cover-img {
        border-radius: .5rem;
        box-shadow: 15px 20px 15px rgba( 0, 0, 0, 0.2);
    }

    @media screen and(max-width:768px) {
        .cover-img {
            width: 100%;
            object-fit: fill;
            border-radius: .5rem;
            box-shadow: 15px 20px 15px rgba( 0, 0, 0, 0.2);
        }
     }
}
