@import "./global.scss";

.post {
    margin: 2rem 0rem;
    border-bottom: 1px solid rgba($primary,0.5);

    .post-img {

        margin: auto 0;

        img {
            width: 100%;
            height: 100%;
            box-shadow: 6px 3px 5px rgba(0,0,0,0.2);
        }
    }

    .post-content {

        margin: auto 0rem;
        @media screen and(max-width:576px) {
            margin-bottom: 2rem;

        }

        .blue-pills {
            margin-bottom: 1.5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            @media screen and(max-width:576px) {
                margin-bottom: 1rem;
  
            }

            span {
                background-color: $primary;
                color: white;
                margin: .5rem;
                padding: .5rem .7rem;
                border-radius: 30px;
                @media screen and(max-width:576px) {
                    margin: .3rem;
                    padding: .2rem .4rem;
                    border-radius: 10px;
                    font-size: .8rem;
      
                }
            }
        }
        h3 {
            font-size: 2rem;
            font-weight: 600;
            font-family: "Poppins";
            margin-bottom: 1.2rem ;
        }
        p {
            font-size: 1rem;
            margin-bottom: 1.25rem;

        }
        a {
            display: inline-block;
            text-decoration: none;
            border: 2px solid blue;
        }
    }
}