
@import "./global.scss";

.achievements {
    margin: 4rem 0;

    .achievement {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -2rem;

        .achievement-img {
            width: 180px;
            height:200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            } //ends image//

        } //ends achievement image
        
        .achievement-info {
            h4 {
                font-weight: 700;
            }
            p {
                font-weight: 550;
            }
        }

    }
}
