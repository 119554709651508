@import "./global.scss";

.social {
    background-color: $primary;
    border-bottom: 2px solid rgb(25, 25, 124);

    .social-wrapper {
        padding: 2rem 0;
        color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media screen and(max-width:576px) {
            flex-direction: column;           
        }

        .connect {
            @include gradient;
            @media screen and(max-width:576px) {
                margin-bottom: 1.5rem;           
            }
        }
    }
}