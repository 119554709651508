.step1 {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
        margin-bottom:2rem ;
        display: inline-block;
        width: 15rem;
        height: 2.8rem;
        padding: .5rem;
        border-radius: 15px;
    }
    button {
        font-size: 1.3rem;
    }
}