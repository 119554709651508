.partners {
    margin: 4rem 0rem;
    .partners-img {
        margin-top: -3rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}