@import "./global.scss";

.form {
    //Common styling.
    .wrapper {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          background:rgba(204, 146, 233, 0.5);
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          padding: 2rem 5rem;
          box-shadow: 5px 5px 30px rgba(0,0,0,0.2);

          @media screen and(max-width:576px) {
            margin: 0;
            padding: 2rem 2rem;
        }
          
          h2, h3 {
            color: $primary;
            font-weight: 600;
            @media screen and(max-width:576px) {
              font-size: 1.5rem;
          }
        }
        button {
            border: blue solid 2px;
            margin-top: 1.5rem;
        }

        .buttons {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                margin-right: .5rem;
                padding: 0.8375rem 4.375rem 0.8375rem 1.25rem;
                font-size: 1rem;
                .prev {
                    left: 5px;
                }
                
            }

            button:first-child {
                padding: 0.8375rem 1.25rem 0.8375rem 4.375rem;
            }
        }

    }

    //step0 styling.
    .step0 {
        padding: 1rem 2.5rem;
        @media screen and(max-width:576px) {
          padding: 1rem .5rem;
      }
        .wrapper .option{
            background:rgba(255,255,255,0.8);
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 10px 0px;
            border-radius: 10px;
            cursor: pointer;
            padding: 15px 10px;
            border: 2px solid lightgrey;
            transition: all 0.3s ease;

            @media screen and(max-width:576px) {
              padding: 10px 5px;
          }
          }
          .wrapper .option .dot{
            height: 20px;
            width: 20px;
            background: rgba(0,0,0,0.2);
            border-radius: 50%;
            position: relative;
          }
          .wrapper .option .dot::before{
            position: absolute;
            content: "";
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            background:$primary;
            border-radius: 50%;
            opacity: 0;
            transform: scale(1.5);
            transition: all 0.3s ease;
          }
          input[type="radio"]{
            display: none;
          }

          // Radio button styling from
          // https://codepen.io/Metty/pen/MWjOavR

          #option-1:checked:checked ~ .option-1,
          #option-2:checked:checked ~ .option-2,
          #option-3:checked:checked ~ .option-3,
          #option-4:checked:checked ~ .option-4{
            border-color: #0069d9;
            background: #0069d9;
          }
          #option-1:checked:checked ~ .option-1 .dot,
          #option-2:checked:checked ~ .option-2 .dot,
          #option-3:checked:checked ~ .option-3 .dot,
          #option-4:checked:checked ~ .option-4 .dot{
            background: #fff;
          }
          #option-1:checked:checked ~ .option-1 .dot::before,
          #option-2:checked:checked ~ .option-2 .dot::before,
          #option-3:checked:checked ~ .option-3 .dot::before,
          #option-4:checked:checked ~ .option-4 .dot::before{
            opacity: 1;
            transform: scale(1);
          }
          .wrapper .option span{
            font-size: 20px;
            color: #808080;
          }
          #option-1:checked:checked ~ .option-1 span,
          #option-2:checked:checked ~ .option-2 span,
          #option-3:checked:checked ~ .option-3 span,
          #option-4:checked:checked ~ .option-4 span{
            color: #fff;
          }
          
        }

        //step1 styling.

        .step1, .step2 {

            input {
                width: 18rem;
                height: 3rem;
                margin: .4rem;
                padding: .3rem .5rem;
                text-align: center;
                border: none;
                background-color: rgba(0,0,0, 0.1);
                border-radius: .5rem;
                font-size: 1.25rem;
                font-weight: 600;
                color: $primary;
            }
        }

        //step3 styling
        select {
            width: 18rem;
                height: 3rem;
                margin: .4rem;
                padding: .3rem .5rem;
                text-align: center;
                border: none;
                background-color: rgba(0,0,0, 0.1);
                border-radius: .5rem;
                font-size: 1.25rem;
                font-weight: 600;
                color: $primary;
        }

        //Result styling
        .result {
          
          .confirmation {
            margin-top: 1rem;
            text-align: left;
            input {
              margin-right: .3rem;
            }
            span {
              color: $primary;
            }
            p {
              margin-top: 0.5rem;
              text-align: justify;
              color: rgb(250, 248, 249);
            }
          }
        }

        //Success styling

        .success {
          text-align: center;
          
          a {
            margin-top: 2rem;
          }
        }
}