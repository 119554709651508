.App {
  text-align: center ;
}


.call{
  position : fixed ;
  bottom: 0.5rem;
  right : 1.5rem;
  background-color: rgb(124, 27, 143);  
  color: white;
  font-size: 2rem;
  padding: 0.75rem;
  border-radius: 50%;
}