@import "./global.scss";

.footer {
    background-color: $primary;
    color: white;
    padding: 1rem 0 1rem 0;

    // Footer-1 styling

    .footer-1 {
        .brand-logo {
            h1 {
                font-size: 2.5rem;
                font-weight: 700;
                font-family: sans-serif;
            }
        }
        .text {
            font-weight: 550;
            margin-bottom: 1.5rem;
        }
        .certificates {
            margin-top: 1rem;
            img {
                width: 75%;
                height: 100%;
            }
        }
    }

    // Footer-2 styling

    .footer-2 {
        h4 {
            font-size:1.5rem;
            font-weight: 550;
            margin-left: -2rem;
        }
        ul {
            list-style-type: none;
            text-align: left;
            
            li {
                margin: .7rem 0;
                a {
                    text-decoration: none;
                    color: whitesmoke;
                    font-weight: 400;
                }
            }
        }
    }
    .footer-3 {
        padding-left: .5rem;
        h4 {font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 1.2rem;
        }
        iframe {
            border-radius: 10px;
            box-shadow: 8px 12px 16px rgba(255, 255, 255, 0.3);
        }
    }
}