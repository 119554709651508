
@import "./global.scss";

.testimonials{
  position: relative;
  padding:0;
  @include gradient ;

  &__card{
    background: rgba($white, 0.8) ;
    padding : 2rem 1.25rem;
    border-radius:0.65rem;
    height:auto;
    text-align: center;

    i{
      font-size: 1.5rem;
      color: rgba($primary, 0.7)
    }//i

    .ratings{
      i{
        color: rgba($primary, 0.7);
        font-size:1rem;
      } // i-rating
    }//rating
  }//testimonials__card

  &__picture{
    width:6rem;
    height:6rem;
    position: absolute;
    left:50%;
    transform : translate(-50%,-50%);

    img{
      border : 0.325rem solid rgba($primary, 0.7) ;
    }//img
  }//testimoials_picture

  &__name{
    padding-top:3rem;
    margin-bottom:1rem;
    text-align:center;
    font-weight:500;
    color:$white;

    h3{
      padding-top:0.8rem;
      text-transform: capitalize;
    }

    p{
      padding-bottom: 2rem;
      text-transform: capitalize;
    }//p
  }// testimonials__name
}// testimonials