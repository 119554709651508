@import "./global.scss";

.menus{

    @include gradient ;
  
    
   li{
      padding : 0 0.7rem;
  
      a{
          color:$white !important;
          text-transform:capitalize;
          font-weight: 600;
      }
  }
  .navbar-brand {
      font-size: 2rem;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-weight: 700;
      color: rgb(246, 250, 250);
  }

  .btn-rounded {
    @media screen and(max-width:576px) {
       // display: none;
    }
  }
  
  }